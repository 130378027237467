<template>
   <div class="card mb-2">
      <div class="card-body pb-0">
         <div class="row m-0">
            <div class="col-xl-11 px-1 mb-3">
               <label><i class="far fa-hashtag color-theme me-1"></i> {{ file.id }}</label>
               <input type="text" class="form-control limitador" :value="file.nomeArquivo" disabled />
            </div>

            <!-- Botão para download do PDF -->
            <div class="col-xl-1 px-1 mb-3">
               <label><i class="far fa-download color-theme me-1"></i>Candidatura</label>
               <input type="text" class="form-control text-center cursor-pointer" :value="'Download'" @click="download($event)" v-if="extensao === 'pdf'" />
            </div>

         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'Anexo',
   props: ['file'],
   data() {
      return {
         extensao: String(this.file.nomeArquivo).split('.').pop()
      };
   },
   methods: {
      download(event) {
         event.preventDefault();
         window.open(this.file.nomeArquivo, '_blank');
         const link = document.createElement('a');
         link.href = this.file.nomeArquivo;
         link.setAttribute('download', this.file.nomeArquivo.split('/').pop());
         document.body.appendChild(link);
         document.body.removeChild(link);
      }
   }
};
</script>

<style scoped>
img {
   height: 120px;
   width: 120px;
}

</style>