<template>
   <div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown"
            data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col mb-3">
                     <label><i class="far fa-search color-theme font-11 me-1"></i> Pesquisar</label>
                     <input type="text" class="form-control" v-model="pesquisa.valor" maxlength="200" v-focus
                        @keyup.enter="buscarPostagens(0)" placeholder="Busque pelo título da vaga" />
                  </div>
                  <div class="col-12 col-sm-5 col-md-3 col-xl-2 px-0 mb-3 align-self-end">
                     <div class="row">
                        <div class="col">
                           <button type="button" class="btn btn-primary w-100" @click="buscarPostagens(0)"><i
                                 class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8">
                           <button type="button" class="btn btn-primary w-100" @click="abrirPostagem(null)"><i
                                 class="far fa-plus font-13 me-1"></i> Adicionar</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12">
         <div class="cards-150 cards-sm-250">
            <Vaga v-for="(vaga, index) in pesquisa.retorno.resultado" :key="index" :index="index" :vaga="vaga"
               @editar="abrirPostagem($event)" />
         </div>
      </div>

      <div class="col-12 px-1 mt-3 mb-2 text-center"
         v-if="pesquisa.retorno.resultado.length > 0 && pesquisa.retorno.resultado.length % 50 == 0 && (pesquisa.retorno.valor == null || String(pesquisa.retorno.valor).trim().length == 0)">
         <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarPostagens(null)">Ver mais</button>
      </div>

      <!-- modalPostagem -->
      <div class="modal fade" id="modalPostagem" aria-labelledby="modalPostagemLabel" data-bs-backdrop="static"
         aria-hidden="true">
         <div class="modal-dialog modal-xxl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalPostagemLabel" v-if="postagem.id != null">Editar postagem</h5>
                  <h5 class="modal-title" id="modalPostagemLabel" v-else>Adicionar postagem</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-2">
                     <div class="card-body p-12">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                           <li class="nav-item col" role="presentation">
                              <button class="nav-link m-0 active" id="pills-basico-tab" data-bs-toggle="pill"
                                 data-bs-target="#pills-basico" type="button" role="tab" aria-controls="pills-basico"
                                 aria-selected="true">
                                 <i class="far fa-home font-12"></i><span class="ms-1 d-none d-sm-inline"> Info.
                                    básica</span>
                              </button>
                           </li>
                           <li class="nav-item col" role="presentation" :class="postagem.id != null ? '' : 'd-none'">
                              <button class="nav-link m-0" id="pills-corpo-tab" data-bs-toggle="pill"
                                 data-bs-target="#pills-corpo" type="button" role="tab" aria-controls="pills-corpo"
                                 aria-selected="false">
                                 <i class="far fa-align-justify font-13"></i><span class="ms-1 d-none d-sm-inline"> Candidaturas
                                    anexadas a está vagas</span>
                              </button>
                           </li>
                        </ul>
                     </div>
                  </div>

                  <div class="tab-content" id="pills-tabContent">
                     <div class="tab-pane fade show active" id="pills-basico" role="tabpanel"
                        aria-labelledby="pills-basico-tab">
                        <div class="card">
                           <div class="card-body pb-0">
                              <div class="row m-0">
                                 <div class="px-1 mb-3" :class="postagem.id != null ? 'col-md-10' : 'col-md-12'">
                                    <label><i class="far fa-heading color-theme me-1"></i> Título</label>
                                    <input type="text" class="form-control" v-model="postagem.titulo" maxlength="250" />
                                 </div>
                                 <div class="col-md-2 px-1 mb-3" :class="postagem.id != null ? '' : 'd-none'">
                                    <label><i class="far fa-tag color-theme font-11 me-1"></i> Encerrar postagem</label>
                                    <v-select name="Desktop"
                                       :options="[{ label: 'Não', value: false }, { label: 'Sim', value: true }]"
                                       v-model="postagem.desabilitado" :reduce="option => option.value"
                                       :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
                                    <select name="Mobile" class="form-control" v-model="postagem.desabilitado">
                                       <option :value="false">Não</option>
                                       <option :value="true">Sim</option>
                                    </select>
                                 </div>
                                 <div class="w-max-content px-1 mb-3 align-self-end" v-if="postagem.banner != null">
                                    <div class="results__finnaly">
                                       <label class="d-block"><i class="far fa-image color-theme me-1"></i> Banner
                                          <small>(21x9)</small></label>
                                       <img :src="postagem.banner" class="wpx-200" />
                                    </div>
                                 </div>
                                 <div class="px-1 mb-3 align-self-end"
                                    :class="postagem.banner == null ? 'col-sm-12' : 'col'">
                                    <div class="form-group file-area">
                                       <label v-if="postagem.banner == null"><i
                                             class="far fa-image color-theme me-1"></i> Banner
                                          <small>(21x9)</small></label>
                                       <input type="file" ref="anexoBanner" accept=".jpg,.jpeg,.png,.webp"
                                          @change="uploadBanner('Banner')" @drop="uploadBanner('Banner')" />
                                       <div class="file-dummy">
                                          <div class="default">Escolher banner</div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-12 px-1 mb-3">
                                    <label><i class="far fa-heading color-theme me-1"></i> Descrição da postagem</label>
                                    <input type="text" class="form-control" v-model="postagem.descricao"
                                       maxlength="255" />
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label><i class="far fa-quote-right color-theme me-1"></i> Conteúdo</label>
                                    <JoditEditor :value="postagem.conteudo" @attValue="postagem.conteudo = $event" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="tab-pane fade" id="pills-corpo" role="tabpanel" aria-labelledby="pills-corpo-tab"
                        :class="postagem.id != null ? '' : 'd-none'">
                        <div class="card">
                           <div class="card-body">
                              <div class="text-center" v-if="anexos.length == 0">
                                 <img src="../../assets/images/icon-pdf.png" alt="pdf">
                                 <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum PDF encontrado</h4>
                              </div>
                              <div v-else>
                                 <anexo v-for="(file, index) in anexos" :key="index" :file="file" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarPostagem">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import Vaga from '@/components/trabalheconosco/Vaga.vue'
import JoditEditor from '@/components/EditorHTML.vue'
import Anexo from '@/components/Anexo.vue'

export default {
   name: 'TrabalheConosco',
   data: function () {
      return {
         pesquisa: { 'pagina': 0, 'valor': '', 'retorno': { 'resultado': [], 'valor': '' } },
         postagem: { 'id': null, 'titulo': '', 'banner': null, 'descricao': '', 'conteudo': '', 'desabilitado': false },
         anexos: [],
         carregando: false,
         showFile: { 'show': false, 'file': null, 'extensao': '' }
      }
   },
   computed: {
      ...mapState({
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      }),
   },
   watch: {
      '$route.params.idTab': function () {
         this.buscarPostagens(0)
      }
   },
   components: {
      Vaga, JoditEditor, Anexo
   },
   methods: {
      buscarPostagens: function (pagina) {

         if (this.carregando) return;
         this.carregando = true

         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.pagina = pagina == null ? ++this.pesquisa.pagina : pagina

         this.$axios({
            method: 'post',
            url: this.urlRest + 'configuracoesAdmin/searchVagas',
            headers: {
               'Content-Type': 'application/json'
            },
            data: this.pesquisa

         }).then(response => {
            if (this.pesquisa.pagina == 0) {
               this.pesquisa.retorno.resultado = []
            }

            this.pesquisa.retorno.valor = response.data.valor

            response.data.resultado.forEach(p => {
               this.pesquisa.retorno.resultado.push(p)
            });
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogarAdmin')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
            this.carregando = false
         });
      },
      abrirPostagem: function (postagem) {
         if (postagem) {
            let postagemCopia = JSON.parse(JSON.stringify(postagem))

            this.postagem = {
               'id': postagemCopia.id,
               'titulo': postagemCopia.titulo,
               'banner': postagemCopia.banner,
               'descricao': postagemCopia.descricao,
               'conteudo': postagemCopia.conteudo,
               'desabilitado': postagemCopia.desabilitado
            }

         } else {
            this.postagem = { 'id': null, 'titulo': '', 'banner': null, 'descricao': '', 'conteudo': '', 'desabilitado': false }
            this.showFile = { 'show': false, 'file': null, 'extensao': '' }
         }

         setTimeout(() => {
            if (this.$refs.anexoBanner) this.$refs.anexoBanner.value = null;

            $('#modalPostagem').modal('show')
         }, 250);

         this.$axios({
            method: 'get',
            url: this.urlRest + 'configuracoesAdmin/getVaga',
            params: {
               id: this.postagem.id
            }

         }).then(response => {
            if (response && response.data) {
               this.anexos = response.data; 
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: Resposta do servidor inválida.'
               });
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogarAdmin')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      salvarPostagem: function () {
         if (this.postagem.titulo == null || String(this.postagem.titulo).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Título inválido!'
            })

            return
         } else if (this.postagem.descricao == null || String(this.postagem.descricao).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Adicione a descrição!'
            })

            return
         } else if (this.postagem.conteudo == null || String(this.postagem.conteudo).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Adicione o conteúdo!'
            })

            return
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest + 'configuracoesAdmin/saveVaga',
            headers: {
               'Content-Type': 'application/json'
            },
            data: this.postagem

         }).then(() => {
            this.buscarPostagens(0)
            $('#modalPostagem').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: 'Postagem salva!'
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogarAdmin')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Vaga não encontrado!'
                  });
               } else if (error.response.status == 409) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Vaga já cadastrada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      uploadBanner: function (tipo) {
         let dataForm = new FormData();
         this.$store.dispatch('alternarTelaCarregamento', true)

         if (tipo == 'Banner') {
            for (let file of this.$refs.anexoBanner.files) {
               dataForm.append('fileAdm', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            }
         }

         this.$axios({
            method: 'post',
            url: this.urlRest + 'configuracoesAdmin/uploadArquivo',
            headers: {
               'Content-Type': 'multipart/form-data'
            },
            data: dataForm

         }).then((response) => {
            if (tipo == 'Banner') {
               this.postagem.banner = response.data[0].url
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      // viewFile: function (file) {
      //    this.showFile = { 'show': true, 'file': file, 'extensao': String(file.nomeArquivo).split('.').pop() }
      // }
   },
   mounted() {
      this.buscarPostagens()
   }
}

</script>